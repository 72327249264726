body {
  max-width: 100vw;
  overflow-x: hidden;
}

.row, .row > * {
  padding: 0;
  margin: 0;
}

.spaceBetween {
  display: inline-flex;
  justify-content: space-between;
}

.col-lg-20 {
  width: 20% !important;
}

.w-10 { width: 10% !important; }
.w-5 { width: 5% !important; }
.w-7 { width: 7% !important; }

.bebas {
  font-family: 'Bebas Neue', cursive;
}

.radius-10 { border-radius: 10px; }

/************************************************
                    TRANSFORM
***********************************************/

.scale:hover { transform: scale(1.02); transition: transform 200ms ease-in-out; cursor: pointer; }

/************************************************
                    DISPLAY
***********************************************/

.inline-block { display: inline-block; }
.inline { display: inline; }

/************************************************
                      TYPO
***********************************************/

.text-primary {
  color: #007bff!important;
}

.text-secondary {
  color: #6c757d!important;
}

.text-success {
  color: #28a745!important;
}

.text-danger {
  color: #dc3545!important;
}

.text-warning {
  color: #ffc107!important;
}

.text-info {
  color: #17a2b8!important;
}

.text-light {
  color: #f8f9fa!important;
}

.text-dark {
  color: #343a40!important;
}

.text-muted {
  color: #6c757d!important;
}

.text-400 { font-weight: 400; }

/************************************************
                  MARGIN/PADDING
***********************************************/

.mt-0 { margin: 0 !important; }

.box {
	padding: 10px;
}

.box-body {
  background-color: #fff;
	border-radius: 5px;
  padding: 20px;	
	position: relative;
	border-color: #121212;
	box-shadow: 0 2px 4px 0 rgba(3,27,78,.05);
}

.modal-content {
	overflow: auto !important;
}

.modal-dialog {
	max-width: 50vw;
}

div#addWorkModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 2;
}

#staticBackdrop, #createStructureModal {
	margin: auto;
	max-width: 100%;
}

.App {
  background: #edf0f7 !important;
  text-align: center;
  min-height: 100vh;
  overflow-x: hidden;
}

#leftSideBar {
  height: 100vh;
  background: transparent;
  /*box-shadow: 0 1px 1px #444;*/
  padding: 0;
}

#leftSideBar .list-group-item:hover {
  cursor: pointer;
}

#leftSideBar .list-group-item.active {
	color: #000;
	background-color: #edf0f7;
	border-color: #edf0f7;
}

div#leftSideBar .list-group-item .d-flex i {
  display: inline-block;
	margin: 0 10px !important;
}

#leftSidebar .d-flex.w-100 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

#leftSideBar .list-group .list-group-item {
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
  justify-content: center;
  border: none;
  background-color: white;
  margin: 5px 0;
  border-radius: 10px;
  width: 90%;
  position: relative;
  left: 15px;
  border-color: #121212;
	box-shadow: 0 2px 4px 0 rgba(3,27,78,.05);
}

#leftSideBar .list-group .list-group-item:hover {
  color: white;
  transition: transform 200ms ease-in-out;
  transform: scale(1.05);
  background-color: rgb(255, 71, 102);
}

.text-left { text-align: left; }
.text-right { text-align: right; }

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.dropdown-menu {
	left: -32px;
}


.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(13,110,253, 0.2);
  border-right: 1.1em solid rgba(13,110,253, 0.2);
  border-bottom: 1.1em solid rgba(13,110,253, 0.2);
  border-left: 1.1em solid #0d6efd;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

#loginContainer {
  width: 100vw;
  height: 100vh;
}

#formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: auto;
  margin: auto;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 50px 25px;
  border-radius: 5px;
}

#loginLeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
}

#loginLeftSide h1 {
  font-size: 7rem;
}

#loginRightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#formContainer input {
  display: block;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #BBB;
  border-radius: 5px;
}

#formContainer button {
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background: rgb(12, 187, 99);
  font-weight: bold;
  color: #FFFFFF;
}

#formContainer form {
  width: 90%;
}

#leftDashboard {
  margin: auto;
}

#leftDashboard, #rightDashboard {
  animation-duration: 0.5s;
}

#rightDashboard {
  position: absolute;
  height: 100vh;
  right: -34%;
}

.showRightDashboard {
  animation-name: showAddWork;
  animation-fill-mode: forwards;
}

.hideRightDashboard {
  animation-name: hideAddWork;
}

@keyframes showAddWork {
  from {
    right: -34%
  }
  to {
    right: 0;
  } 
}

@keyframes hideAddWork {
  from {
    right: 0
  }
  to {
    right: -34%;
  } 
}

.actionsContainer {
  width: 70px;
}

.actionsContainer img {
  width: 40%;
  cursor: pointer;
}

.progress-bar {
	font-weight: bold;
	font-size: 0.9rem;
}

/* Progress Bar 1 */

.progress1 {
	background: #EEE;
	justify-content: flex-start;
	border-radius: 100px;
	align-items: center;
	position: relative;
	padding: 0 2px;
	display: flex;
	height: 20px;
	width: 70%;
  margin-right: 5px;
}

.progress-value1 {
	animation: load 2s normal forwards;
	border-radius: 100px;
	background: #e03c83;
	height: 16px;
	width: 0;
}

/* Progress Bar 2 */

.progress2 {
	background: rgba(0, 0, 0, 0.1);
	justify-content: flex-start;
	border-radius: 100px;
	align-items: center;
	position: relative;
	padding: 0;
	display: flex;
	height: 25px;
	width: 100%;
}

.progress-value2 {
	animation: load 3s normal forwards;
	box-shadow: 0 10px 40px -10px #fff;
	border-radius: 100px;
	background: #7f3690;
	height: 25px;
	width: 0;
}

@keyframes load {
	0% {
		width: 0;
	}
}

.calendarContainer img {
  width: 100%;
}

.calendarCell {
  display: inline-block;
  position: relative;
  width: 13%;
  height: 100px;
  margin: 2px;
  vertical-align: top;
}

.calendarCell:hover {
  background-color: #c2d4d8 !important;
  cursor: pointer;
}

.structureContainer {
  box-sizing: border-box;
  max-width: 22%;
  display: inline-block;
  background-color: #fff !important;
	margin: 20px 15px 15px 0;
	padding: 0 0 5px 0;
  animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-name: displayStructure;
}

@keyframes displayStructure {
  from {
    margin-top: -5px;
    opacity: 0;
  }

  to {
    margin-top: 20px;
    opacity: 1;
  }
}

.structureLogo {
	width: 25px;
	display: inline-block;
	position: relative;
	top: 6px;
	padding: 0;
	left: 8px;
}

.structureNameContainer {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
	text-align: left;
	position: relative;
	top: -10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 20px;
  box-sizing: border-box;
}

.structureCircleImg {
	background: #fff;
	border-radius: 50%;
	height: 40px;
	display: flex;
	width: 40px;
	align-items: center;
	justify-content: center;
}

.structureName {
	display: inline-block;
	border-radius: 3px 3px 0 0;
	margin-left: 10px;
}

.structureProgress {
	height: 8px;
	width: 95%;
	position: relative;
	top: 14px;
}

.diffContainer img {
	width: 30px;
  margin: 5px;
  position: relative;
  top: -3px;
}

.myStructureNameRow {
  margin-bottom: 10px;
}

.myStructureNameRow > button {
  background: none;
  border: none;
}

.myStructureNameContainer {
  text-align: left;
  border-radius: 4px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.swatches-picker {
	width: 100% !important;
	height: auto !important;
}

.detailsMonthWorked {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px)
{
  #formContainer {
    width: 95vw;
    padding: 50px 20px;
  }
}

.boxTitle {
	border-radius: 5px;
	color: white;
	padding: 5px;
	margin-bottom: 10px;
}

.boxTitleGreen {
  background: #00b894;
}

.boxTitleRed {
  background: #ff7675;
}

.boxTitleBlue {
  background: #74b9ff;
}

.homeDashboardCard img {
  width: 25%;
}

.homeDashboardCard h2 {
	/*font-size: 3.5rem;*/
	font-weight: bold;
	color: #3b6080;
}